import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Main from "./components/layout/Main";
import "react-quill/dist/quill.snow.css";
import AppContext from "./store/app.context";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { notification } from "antd";
import RegisterPage from "./pages/auth/RegisterPage";
import LoginPage from "./pages/auth/LoginPage";
import SchoolPage from "./pages/master/schools";
import DashboardPage from "./pages/master/dashboard";
import UserPage from "./pages/master/users";
import TransactionPage from "./pages/master/transactions";
import SubjectPage from "./pages/master/subjects";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import dayjs from "dayjs";
import ModulePage from "./pages/master/modules";
import BillPage from "./pages/master/bills";
import { requestGetProfile, requestRefreshToken } from "./services/user.service";
import NotificationPage from "./pages/notifications";
import WhatsappBroadcastPage from "./pages/master/whatsapp/broadcasts";

require("dayjs/locale/id");

dayjs.extend(utc);
dayjs.extend(tz);

dayjs.locale("id");
dayjs.tz.setDefault("Asia/Jakarta");

const firebaseConfig = {
	apiKey: "AIzaSyBsmfJ-_osMGQ62UjlbmEe8B67si06hWtU",
	authDomain: "codeiva-smartiva.firebaseapp.com",
	projectId: "codeiva-smartiva",
	storageBucket: "codeiva-smartiva.appspot.com",
	messagingSenderId: "781106445573",
	appId: "1:781106445573:web:4173828a36b1c1faf0cb6c",
	measurementId: "G-JKLW1FCX4X",
};

const vapidKey =
	"BEQonUIHCPsKHIUtwhYS5Q2M0uZcC6wJEC-R38QDJWBozdn2d-IkLszAaSA-Ex5r--oQBJz9-zxOekYhndqPaOU";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
	const notification = new Notification("Smartiva", {
		body: payload?.data?.body,
		icon: "/favicon.png",
	});

	notification.onclick = () => {
		window.focus();
	};
});

function App() {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	axios.defaults.withCredentials = true;
	axios.defaults.headers.get["X-Language"] = "id";
	axios.defaults.headers.get["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.post["X-Language"] = "id";
	axios.defaults.headers.post["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.patch["X-Language"] = "id";
	axios.defaults.headers.patch["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.delete["X-Language"] = "id";
	axios.defaults.headers.delete["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			console.log(error.response);
			if (error.response.status === 401) {
				context.logout();
				navigate("/login");
			}
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		if (context.isLoggedIn) {
			requestPermission();

			requestGetProfile().then((response) => {
				if (response.data && response.data.data) {
					context.setUser(response.data.data);
				}
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.isLoggedIn]);

	function requestPermission() {
		Notification.requestPermission().then((permission) => {
			const oldToken = localStorage.getItem("messaging_token");
			if (permission === "granted") {
				getToken(messaging, {
					vapidKey: vapidKey,
				}).then((currentToken) => {
					if (currentToken && currentToken !== oldToken) {
						localStorage.setItem("messaging_token", currentToken);

						requestRefreshToken(currentToken)
							.then((response) => {
								logEvent(analytics, "messaging_token_updated", {
									token: currentToken,
									user: {
										id: context.user._id,
										name: context.user.name,
									},
								});
							})
							.catch((error) => {
								console.error("Error refreshing token: ", error);
							});
					}
				});
			} else {
				notification.error({
					message: "Izin ditolak!",
					description: "Anda tidak akan menerima notifikasi dari aplikasi ini.",
				});
			}
		});
	}

	return (
		<div className="App">
			<Routes>
				{!context.isLoggedIn && <Route path="/register" element={<RegisterPage />} />}
				{!context.isLoggedIn && <Route path="/login" element={<LoginPage />} />}
				{!context.isLoggedIn && <Route path="/forgot-password" element={<ForgotPasswordPage />} />}

				{/* Admin Start */}
				{context.isLoggedIn && (context.role === "MASTER" || context.role === "ADMIN") && (
					<>
						<Route
							path="/master/dashboard"
							element={
								<Main>
									<DashboardPage />
								</Main>
							}
						/>
						<Route
							path="/master/modules"
							element={
								<Main>
									<ModulePage />
								</Main>
							}
						/>
						<Route
							path="/master/schools"
							element={
								<Main>
									<SchoolPage />
								</Main>
							}
						/>
						<Route
							path="/master/users"
							element={
								<Main>
									<UserPage />
								</Main>
							}
						/>
						<Route
							path="/master/bills"
							element={
								<Main>
									<BillPage />
								</Main>
							}
						/>
						<Route
							path="/master/transactions"
							element={
								<Main>
									<TransactionPage />
								</Main>
							}
						/>
						<Route
							path="/master/subjects"
							element={
								<Main>
									<SubjectPage />
								</Main>
							}
						/>
						<Route
							path="/master/whatsapp/broadcasts"
							element={
								<Main>
									<WhatsappBroadcastPage />
								</Main>
							}
						/>
						<Route
							path="/*"
							element={
								context.isLoggedIn ? <Navigate to="/master/dashboard" /> : <Navigate to="/login" />
							}
						/>
					</>
				)}
				{/* Admin End */}

				{/* User Start */}
				{context.isLoggedIn && (
					<>
						<Route
							path="/notifications"
							element={
								<Main>
									<NotificationPage />
								</Main>
							}
						/>
					</>
				)}
				{/* User Start */}

				{/* Default */}
				<Route path="/*" element={<Navigate to="/login" />} />
				{/* Default */}
			</Routes>
		</div>
	);
}

export default App;
