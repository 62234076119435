import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/plus-jakarta-sans";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./store/app.context";
import { App as AntdApp, ConfigProvider } from "antd";
import locale from "antd/locale/id_ID";
import "antd/dist/reset.css";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<AppContextProvider>
		<ConfigProvider
			locale={locale}
			theme={{
				token: {
					colorPrimary: "#01ABE9",
					colorSuccess: "#29D697",
					colorWarning: "#FFC107",
					colorError: "#F2583E",
					colorTextHeading: "#333333",
					colorText: "#333333",
					fontFamily: "Plus Jakarta Sans, sans-serif",
				},
			}}>
			<AntdApp>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</AntdApp>
		</ConfigProvider>
	</AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
