/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, Input, Modal, Popconfirm, Select, Spin, theme } from "antd";
import ReactQuill from "react-quill";
import {
	requestAddWhatsappBroadcast,
	requestGetSchoolList,
	requestGetUserList,
} from "../../../../../services/master.service";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useEffect, useState } from "react";

const BroadcastFormModal = ({ isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { message, notification } = App.useApp();

	const [form] = Form.useForm();
	const msg = Form.useWatch("message", form);
	const role = Form.useWatch("role", form);
	const schoolId = Form.useWatch("schoolId", form);
	const [formattedMessage, setFormattedMessage] = useState("");

	const [schools, setSchools] = useState([]);
	const roles = [
		{ value: "ADMIN", label: "Admin" },
		{ value: "TEACHER", label: "Guru" },
		{ value: "STUDENT", label: "Siswa" },
		{ value: "PARENT", label: "Orang Tua/Wali" },
	];

	const [users, setUsers] = useState([]);

	useEffect(() => {
		fetchSchools();
	}, []);

	useEffect(() => {
		if (schoolId && role) {
			fetchUsers(schoolId._id, role);
		}
	}, [schoolId, role]);

	const fetchSchools = () => {
		requestGetSchoolList({
			limit: 1000,
		})
			.then((response) => {
				setSchools(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
					setSchools([]);
				}
			});
	};

	const fetchUsers = () => {
		requestGetUserList({
			limit: 1000,
			order: "name:asc",
			filter: `school:${schoolId},role:${role}`,
		})
			.then((response) => {
				setUsers(response.data.data);
				console.log(response.data.data.length);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
					setUsers([]);
				}
			});
	};

	const {
		token: { borderRadius },
	} = theme.useToken();

	useEffect(() => {
		let whatsappText = "";
		if (msg) {
			// form a line, example <p>a</p><p>b</p> -> a\nb
			whatsappText = msg.replace(/<br>/g, "").replace(/<p>/g, "").replace(/<\/p>/g, "\n");

			// add space after </strong> as much as space before it, example <p><strong>hello </strong>wkwk</p> -> <p><strong>hello </strong> wkwk</p>
			whatsappText = whatsappText
				.replace(/<strong>\s+/g, " <strong>")
				.replace(/(<strong>[^<]*?)\s+<\/strong>/g, "$1</strong> ")
				.replace(/<em>\s+/g, " <em>")
				.replace(/(<em>[^<]*?)\s+<\/em>/g, "$1</em> ")
				.replace(/<u>\s+/g, " <u>")
				.replace(/(<u>[^<]*?)\s+<\/u>/g, "$1</u> ")
				.replace(/<s>\s+/g, " <s>")
				.replace(/(<s>[^<]*?)\s+<\/s>/g, "$1</s> ");

			// form a bold, make sure to trim what inside strong element, example <strong>a </strong> -> *a* and the rest italic, underline, and strike
			whatsappText = whatsappText
				.replace(/<strong>(.*?)<\/strong>/g, (match, p1) => {
					return `*${p1.trim()}*`;
				})
				.replace(/<em>(.*?)<\/em>/g, (match, p1) => {
					return `_${p1.trim()}_`;
				})
				.replace(/<u>(.*?)<\/u>/g, (match, p1) => {
					return `_${p1.trim()}_`;
				})
				.replace(/<s>(.*?)<\/s>/g, (match, p1) => {
					return `~${p1.trim()}~`;
				});

			setFormattedMessage(whatsappText);
		}
	}, [msg]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				values.message = formattedMessage;
				values.recipients = users.map((user) => {
					return {
						userId: user._id,
						name: user.name,
						phone: user.phone,
					};
				});

				requestAddWhatsappBroadcast(values)
					.then((response) => {
						message.success(response.data.message);
						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				width={512}
				title={"Tambah Broadcast WhatsApp"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data mata pelajaran?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						{/* name, description, rich text for message, and select school, role, and users */}
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={{
								required: true,
								message: "Masukkan nama broadcast",
							}}
							tooltip="Masukkan nama broadcast">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="description"
							label="Deskripsi"
							required
							rules={{
								required: true,
								message: "Masukkan deskripsi broadcast",
							}}
							tooltip="Masukkan deskripsi broadcast">
							<Input.TextArea style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="message"
							label="Pesan"
							required
							rules={{
								required: true,
								message: "Masukkan pesan broadcast",
							}}
							tooltip="Masukkan pesan broadcast">
							<Input.TextArea style={{ width: "100%", display: "none" }} />
							<div
								style={{
									border: "1px solid #d9d9d9",
									borderRadius: borderRadius,
									marginTop: 4,
									marginBottom: 4,
								}}>
								<ReactQuill
									theme="bubble"
									value={form.getFieldValue("message")}
									onChange={(value) => form.setFieldsValue({ message: value })}
									modules={{
										toolbar: ["bold", "italic", "underline", "strike"],
									}}
									formats={["bold", "italic", "underline", "strike"]}
								/>
							</div>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="schoolId"
							label="Sekolah"
							required
							rules={{
								required: true,
								message: "Pilih sekolah",
							}}
							tooltip="Pilih sekolah">
							<Select
								showSearch
								style={{ width: "100%" }}
								placeholder="Pilih sekolah"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{schools.map((school) => (
									<Select.Option key={school._id} value={school._id}>
										{school.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="role"
							label="Peran"
							required
							rules={{
								required: true,
								message: "Pilih peran",
							}}
							tooltip="Pilih peran">
							<Select
								showSearch
								style={{ width: "100%" }}
								placeholder="Pilih peran"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{roles.map((role) => (
									<Select.Option key={role.value} value={role.value}>
										{role.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default BroadcastFormModal;
