import React from "react";
import { Input } from "antd";

const Search = Input.Search;

const SearchInput = ({ placeholder = "Cari sesuatu...", onSearch, onChange, ...props }) => (
	<div {...props}>
		<Search placeholder={placeholder} onSearch={onSearch} onChange={onChange} />
	</div>
);

export default SearchInput;
