/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	Input,
	Modal,
	App,
	Popconfirm,
	Spin,
	Upload,
	Select,
	InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { requestAddModule, requestEditModule } from "../../../../services/master.service";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";

const ModuleFormModal = ({ module, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { message, notification, modal } = App.useApp();
	const [logoUrl, setLogoUrl] = useState();
	const [logo, setLogo] = useState();

	const [form] = Form.useForm();

	useEffect(() => {
		if (module) {
			form.setFieldsValue({
				name: module.name,
				code: module.code,
				description: module.description,
				logo: module.logo,
				priceActual: module.price.actual,
				priceType: module.price.type,
			});
			setLogoUrl(module.logoFile?.url);
		}
	}, [module]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				const formData = new FormData();
				formData.append("name", values.name);
				formData.append("code", values.code);
				formData.append("description", values.description);
				formData.append("price[actual]", values.priceActual);
				formData.append("price[type]", values.priceType);
				if (logo) {
					formData.append("logo", logo);
				}

				if (module) {
					formData.append("id", module._id);
					requestEditModule(formData)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddModule(formData)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		setLogoUrl(null);
		onClose();
	};

	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Image must smaller than 2MB!");
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			setLogo(file);
			setLogoUrl(reader.result);
			// imageCompression(file, options)
			// 	.then(function (compressedFile) {
			// 		console.log("compressedFile", compressedFile); // true
			// 		setLogoUrl(reader.result);
			// 	})
			// 	.catch(function (error) {
			// 		if (error.response && error.response.data) {
			// 			notification["error"]({
			// 				message: "Kesalahan!",
			// 				description: error.response.data.message,
			// 			});
			// 		} else {
			// 			notification["error"]({
			// 				message: "Kesalahan!",
			// 				description: error.message,
			// 			});
			// 		}
			// 	});
		};

		// then upload `file` from the argument manually
		return false;
	};

	const uploadButton = (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div
				style={{
					marginTop: 8,
				}}>
				Upload
			</div>
		</div>
	);

	return (
		<>
			<Modal
				width={512}
				title={module === null || module === undefined ? "Tambah Modul" : "Ubah Modul"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data modul?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{module ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							label="Nama"
							style={{ marginTop: 0, marginBottom: 0 }}
							name="name"
							rules={[{ required: true, message: "Masukkan nama" }]}>
							<Input style={{ width: "100%" }} placeholder="Nama" />
						</Form.Item>
						{/* Code */}
						<Form.Item
							label="Kode"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="code"
							rules={[{ required: true, message: "Masukkan kode" }]}>
							<Input style={{ width: "100%" }} placeholder="Kode" />
						</Form.Item>
						{/* logo with input image */}
						<Form.Item label="Logo" style={{ marginTop: 12, marginBottom: 0 }} name="logo">
							<Upload
								name="avatar"
								listType="picture-card"
								showUploadList={false}
								beforeUpload={beforeUpload}>
								{logoUrl ? (
									<img
										src={logoUrl}
										alt="avatar"
										style={{
											width: "100%",
										}}
									/>
								) : (
									uploadButton
								)}
							</Upload>
						</Form.Item>
						<Form.Item
							label="Deskripsi"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="description"
							rules={[{ required: true, message: "Masukkan deskripsi" }]}>
							<Input.TextArea style={{ width: "100%" }} rows={2} placeholder="Deskripsi" />
						</Form.Item>
						{/* Price */}
						<Form.Item
							label="Harga"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="priceActual"
							rules={[{ required: true, message: "Masukkan harga" }]}>
							<InputNumber style={{ width: "100%" }} placeholder="Harga" />
						</Form.Item>
						{/* Price Type */}
						<Form.Item
							label="Tipe Harga"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="priceType"
							rules={[{ required: true, message: "Masukkan tipe harga" }]}>
							<Select placeholder="Tipe Harga">
								<Select.Option value="FLAT">Harga Tetap</Select.Option>
								<Select.Option value="UNIT">Harga Per Unit</Select.Option>
							</Select>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default ModuleFormModal;
