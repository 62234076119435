/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { requestGetModuleList } from "../../../services/master.service";
import dayjs from "dayjs";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import ModuleFormModal from "./components/ModuleFormModal";
import { format } from "../../../utils/Formatter";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
	},
	{
		title: "Kode",
		dataIndex: "code",
		key: "code",
		sorter: true,
	},
	{
		title: "Harga",
		dataIndex: "price",
		key: "price",
		sorter: true,
		render: (price, record) => {
			return (
				<span>
					{format(price.actual, { type: "currency" })} <Tag color="blue">{record.price.type}</Tag>
				</span>
			);
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "edit",
		label: "Edit",
		icon: <HiOutlinePencil />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const ModulePage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { message, notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchModuleList();
		// eslint-disable-next-line no-use-before-define
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [modules, setModules] = useState([]);
	const [mappedModules, setMappedModules] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [module, setModule] = useState(null);
	const [isModuleFormVisible, setModuleFormVisible] = useState(false);

	const fetchModuleList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetModuleList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setModules(response.data.data);
				updateMappedModule(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedModule = (data) => {
		setMappedModules(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				code: d.code,
				price: d.price,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus sekolah ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setModuleFormVisible(true);
	};

	const handleEditButton = (module) => {
		setModule(module);
		setModuleFormVisible(true);
	};

	const handleDeleteButton = (id) => {
		// setLoading(true);
		// deleteModule(id)
		// 	.then((response) => {
		// 		if (response.data.code === 200) {
		// 			notification["success"]({
		// 				message: "Good job!",
		// 				description: response.data.message,
		// 			});
		// 			fetchModuleList();
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		if (error.response && error.response.data) {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.response.data.message,
		// 			});
		// 		} else {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.message,
		// 			});
		// 		}
		// 	})
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	const handleOnCloseModuleForm = () => {
		setModule(null);
		setModuleFormVisible(false);
	};

	const handleOnSuccessModuleForm = () => {
		setModule(null);
		setModuleFormVisible(false);
		fetchModuleList();
	};

	return (
		<>
			<ModuleFormModal
				isLoading={isLoading}
				isVisible={isModuleFormVisible}
				onLoading={(v) => setLoading(v)}
				module={module}
				onClose={handleOnCloseModuleForm}
				onSuccess={handleOnSuccessModuleForm}
			/>
			<Card
				bordered={false}
				title="Daftar Sekolah"
				extra={
					<Space>
						<SearchInput placeholder="Nama sekolah" onSearch={handleSearch} />
						<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
							Tambah
						</Button>
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedModules}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default ModulePage;
