import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Layout,
	Button,
	Row,
	Col,
	Typography,
	Form,
	Input,
	Card,
	Checkbox,
	message,
	notification,
} from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import AppContext from "../../store/app.context.tsx";

const { Title } = Typography;
const { Footer } = Layout;

const LoginPage = (props) => {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);

	const onSubmit = (values) => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				axios
					.post(process.env.REACT_APP_API_URL + "/auth/login", {
						email: values.email,
						password: values.password,
					})
					.then((response) => {
						if (response.data.code === 200) {
							message.success("Halo, selamat datang ^_^");

							context.login(response.data.data);
							navigate("/dashboard");
						} else {
							notification.error({
								message: "Kesalahan!",
								description: response.data.message,
							});
						}
					})
					.catch((error) => {
						if (error.response !== undefined) {
							let message = error.response.data.message + ". ";
							if (error.response.data.errors !== undefined) {
								Object.keys(error.response.data.errors).forEach((key) => {
									message += `${error.response.data.errors[key]}. `;
								});
							}
							notification.error({
								message: "Kesalahan!",
								description: message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const onError = (errorInfo) => {};

	return (
		<Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
			<Col xs={24} sm={24} md={12} lg={12} xl={8}>
				<Card style={{ margin: "24px" }}>
					<Title level={4}>Login</Title>
					<p>Untuk melanjutkan Anda harus login dulu ya</p>
					<Form
						form={form}
						className="login-form"
						initialValues={{ remember: true }}
						onFinishFailed={onError}
						onFinish={onSubmit}>
						<Form.Item name="email" rules={[{ required: true, message: "Masukkan email Anda" }]}>
							<Input
								size="large"
								prefix={<MailOutlined className="site-form-item-icon" />}
								placeholder="Email"
							/>
						</Form.Item>
						<Form.Item
							name="password"
							rules={[{ required: true, message: "Masukkan password Anda" }]}>
							<Input.Password
								size="large"
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Password"
							/>
						</Form.Item>
						<Form.Item>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}>
								<Form.Item name="remember" valuePropName="checked" noStyle>
									<Checkbox>Remember me</Checkbox>
								</Form.Item>

								<Link className="login-form-forgot" to={"/forgot-password"}>
									Forgot password
								</Link>
							</div>
						</Form.Item>

						<Form.Item>
							<Button
								loading={isLoading}
								type="primary"
								htmlType="submit"
								style={{
									width: "100%",
								}}
								size="large">
								Log in
							</Button>
						</Form.Item>
						<Typography.Text
							style={{
								display: "block",
								textAlign: "center",
								marginBottom: "16px",
							}}>
							Belum punya akun?{" "}
							<Link to="/register" className="font-bold text-dark">
								Daftar
							</Link>
						</Typography.Text>
					</Form>
					<Footer style={{ marginTop: 24 }}>
						<Typography.Text className="copyright auth">
							Copyright © {new Date().getFullYear()} Smartiva from{" "}
							<Typography.Link href="https://www.codeiva.com">Codeiva</Typography.Link>.
						</Typography.Text>
					</Footer>
				</Card>
			</Col>
		</Row>
	);
};

export default LoginPage;
