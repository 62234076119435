/* eslint-disable no-unused-vars */
import { useEffect, useContext, useState } from "react";
import {
	Breadcrumb,
	Button,
	Input,
	App,
	Space,
	Layout,
	Avatar,
	MenuProps,
	Dropdown,
	Badge,
	notification,
	Typography,
	theme,
	Popover,
} from "antd";
import {
	HomeTwoTone,
	LogoutOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	NotificationFilled,
	RightOutlined,
	SearchOutlined,
	SettingOutlined,
	UnorderedListOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import AppContext, { User } from "../../store/app.context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { requestGetNotificationList } from "../../services/user.service";
import { formatTime } from "../../utils/TimeFormatter";

const { Header } = Layout;

type NotificationType = {
	_id: string;
	userId: string;
	type: string;
	title: string;
	body: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
};

const pageNames: Record<string, string> = {
	master: "HQ",
	dashboard: "Dasbor",
	modules: "Modul",
	users: "Pengguna",
	schools: "Sekolah",
	bills: "Tagihan",
	subjects: "Mata Pelajaran",
	notifications: "Notifikasi",
};

function CustomHeader({
	onSearch,
	collapsed,
	setCollapsed,
}: {
	onSearch: (value: any) => void;
	collapsed: boolean;
	setCollapsed: (value: boolean) => void;
}) {
	const { message, notification } = App.useApp();
	const context = useContext(AppContext);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");
	const [notifications, setNotifications] = useState<NotificationType[]>([]);
	const [scrolling, setScrolling] = useState(false);

	const logoutHandler = () => {
		axios
			.post(process.env.REACT_APP_API_URL + "/auth/logout")
			.then((response) => {
				if (response.data.code === 200) {
					message.success("Berhasil keluar, sampai jumpa kembali ^_^");

					context.logout();
					navigate("/login");
				} else {
					notification.error({
						message: "Kesalahan!",
						description: response.data.message,
					});
				}
			})
			.catch((error) => {
				notification.error({
					message: "Kesalahan!",
					description: error.message,
				});
			});
	};

	useEffect(() => window.scrollTo(0, 0));

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 0) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const {
		token: { colorPrimary, borderRadius, colorBgBase },
	} = theme.useToken();

	useEffect(() => {
		fetchNotificationList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchNotificationList = () => {
		requestGetNotificationList({
			limit: 10,
			filter: "status:UNREAD",
		})
			.then((response) => {
				setNotifications(response.data.data);
			})
			.catch((error) => {
				notification.error({
					message: "Kesalahan!",
					description: error.message,
				});
			});
	};

	return (
		<Header
			style={{
				height: "72px",
				display: "flex",
				alignItems: "center",
				position: "sticky",
				top: 0,
				zIndex: 10,
				background: "#f5f5f5",
				padding: "0 16px",
				transition: "all 0.3s",
				boxShadow: scrolling ? "0 2px 8px rgba(0, 0, 0, 0.1)" : "none",
			}}>
			<Button
				type="default"
				icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
				onClick={() => setCollapsed(!collapsed)}
				style={{
					fontSize: "16px",
					marginRight: 16,
				}}
			/>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "16px",
				}}>
				<div
					style={{
						display: "flex",
						height: "32px",
						alignItems: "center",
						borderRadius: borderRadius,
						background: colorBgBase,
						border: `1px solid ${colorPrimary}`,
						padding: "0 8px",
					}}>
					<Breadcrumb
						separator={<RightOutlined style={{ fontSize: "10px" }} />}
						items={[
							{
								title: <HomeTwoTone />,
							},
							...page.split("/").map((item) => ({
								title: pageNames[item] || item,
							})),
						]}
					/>
				</div>
				<Space size="middle" align="center">
					<Input
						onChange={(e) => onSearch(e.target.value)}
						style={{ width: "300px" }}
						placeholder="Cari sesuatu..."
						prefix={<SearchOutlined />}
					/>
					<NotificationButton notifications={notifications} />
					<ProfileButton
						user={context.user}
						onLogout={() => {
							logoutHandler();
						}}
					/>
				</Space>
			</div>
		</Header>
	);
}

function NotificationButton({ notifications }: { notifications: NotificationType[] }) {
	const navigate = useNavigate();

	const items: MenuProps["items"] = [
		...notifications.map((notification) => ({
			label: (
				<Popover
					content={
						<div
							style={{
								maxWidth: "300px",
								display: "flex",
								flexDirection: "column",
								alignItems: "end",
							}}>
							<Typography.Text
								style={{
									width: "100%",
									textAlign: "start",
								}}>
								{notification.body}
							</Typography.Text>
							<Typography.Text type="secondary">
								{formatTime(notification.createdAt)}
							</Typography.Text>
						</div>
					}
					title={notification.title}
					placement="leftTop">
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}
						onClick={() => {
							navigate("/notifications");
						}}>
						<Typography.Text strong>{notification.title}</Typography.Text>
						<Typography.Text
							type="secondary"
							style={{
								fontSize: "12px",
								width: "100%",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								textAlign: "right",
							}}>
							{formatTime(notification.createdAt)}
						</Typography.Text>
					</div>
				</Popover>
			),
			key: notification._id,
		})),
		{
			type: "divider",
		},
		{
			label: "Lihat Semua",
			key: "view-all",
			icon: <UnorderedListOutlined />,
		},
	];

	return (
		<Dropdown
			menu={{
				items,
				onClick: ({ key }) => {
					if (key === "view-all") {
						navigate("/notifications");
					}
				},
			}}
			trigger={["click"]}
			placement="bottomRight"
			overlayStyle={{
				width: "300px",
			}}>
			<Badge
				count={notifications.length}
				overflowCount={9}
				style={{
					cursor: "pointer",
				}}>
				<Avatar
					shape="square"
					style={{
						cursor: "pointer",
						background: "#01ABE9",
					}}>
					<NotificationFilled />
				</Avatar>
			</Badge>
		</Dropdown>
	);
}

function ProfileButton({ user, onLogout }: { user: User; onLogout: () => void }) {
	const items: MenuProps["items"] = [
		{
			key: "greeting",
			label: (
				<Space direction="vertical" size={0}>
					<Typography.Text strong>Halo, {user?.name}! 👋</Typography.Text>
					<Typography.Text
						type="secondary"
						style={{
							fontSize: "12px",
						}}>
						{user?.email}
					</Typography.Text>
				</Space>
			),
		},
		{
			type: "divider",
		},
		{
			label: "Profil",
			key: "profile",
			icon: <UserOutlined />,
		},
		{
			label: "Pengaturan",
			key: "settings",
			icon: <SettingOutlined />,
		},
		{
			type: "divider",
		},
		{
			label: "Logout",
			key: "logout",
			icon: <LogoutOutlined />,
			danger: true,
		},
	];

	return (
		<Dropdown
			menu={{
				items,
				onClick: ({ key }) => {
					if (key === "profile") {
						notification.info({
							message: "Profil",
							description: "Fitur ini belum tersedia",
						});
					} else if (key === "settings") {
						notification.info({
							message: "Pengaturan",
							description: "Fitur ini belum tersedia",
						});
					} else if (key === "logout") {
						onLogout();
					}
				},
			}}
			trigger={["click"]}
			placement="bottomRight"
			overlayStyle={{
				width: "200px",
			}}>
			<Avatar
				shape="square"
				style={{
					cursor: "pointer",
					background: "#01ABE9",
				}}>
				{user?.name?.charAt(0).toUpperCase()}
			</Avatar>
		</Dropdown>
	);
}

export default CustomHeader;
